import { Header } from 'shared/ui/header/ui';
import { Button } from 'shared/ui';
import { Path } from 'shared/config';
import { api } from 'shared/api';

import { ReactComponent as SadIcon } from '../../assets/sad_face.svg';

import { useNavigate, generatePath } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { postAuthRefresh } from 'entities/auth/api';

import classNames from 'classnames';
import styles from './not-found.module.scss';

export const NotFound = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    postAuthRefresh({
      refresh: localStorage.getItem(`refresh`) as string,
    }).then(() => {
      /* global process */
      fetch(process.env.REACT_APP_BACKEND_URL + '/rooms/', {
        headers: {
          Authorization: api.defaults.headers.common.Authorization as string,
        },
      })
        .then((res) => res.json())
        .then(
          () => {
            setIsLoaded(true);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          },
        );
    });
  }, []);

  return (
    <>
      <Header dark onBack={() => navigate(Path.RoomsList)} title="Комнаты" background="transparent" />

      <div className={classNames(styles.attemptLabel, styles['center-div'])}>
        {!isLoaded ? (
          <p className={styles['info-component']}>Загрузка...</p>
        ) : (
          <div>
            <SadIcon />
            <p className={styles.bold}>{error ? error : 'Такой комнаты у нас еще нет или у нее истек срок работы'}</p>
          </div>
        )}
      </div>

      <Button className={styles['create-room']} onClick={() => navigate(generatePath(Path.Guess))}>
        В общую комнату
      </Button>
    </>
  );
};
