import React from 'react';

import styles from './engineering-works.module.scss';
import { ReactComponent as HammerWrench } from '../../assets/hammer-and-wrench.svg';
import classNames from 'classnames';

export const Works = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>ОТГАДАЙ СЛОВО</h3>
        <div className={classNames(styles['attempt-label'], styles.centerDiv)}>
          <HammerWrench />
          <p className={styles.bold}>
            Ведутся технические работы. Следите за новостями в <br />
            <a href="https://t.me/guesswordcom">https://t.me/guesswordcom</a>
          </p>
        </div>
      </div>
    </>
  );
};
