import { Header } from 'shared/ui/header/ui';
import { Path } from 'shared/config';
import { truncateString } from 'shared/lib';
import { generatePath, NavigateFunction, useNavigate } from 'react-router-dom';
import { postAuthRefresh } from 'entities/auth/api';

import styles from './roomslist.module.scss';
import React, { useEffect, useState, useCallback, Dispatch, SetStateAction, useRef, forwardRef, PointerEventHandler } from 'react';
import { api } from 'shared/api';
import { Button } from 'shared/ui';
import { ReactComponent as SadIcon } from '../../assets/sad_face.svg';
import { ReactComponent as TrashBin } from '../../assets/trash_bin.svg';
import classNames from 'classnames';
import { deleteRoom } from 'entities/rooms/api';
import { Modal } from 'shared/ui/modal/ui/modal';
import type { Definitions } from 'backend';

interface IRoomComponent {
  room: Definitions.Room;
  navigate: NavigateFunction;
  setDeleteModalIsVisible: Dispatch<SetStateAction<string | undefined | null>>;
  deleteModalIsVisible: string | undefined | null;
  handleDeleteRoom: () => void;
  onPointerDown: PointerEventHandler<HTMLDivElement>;
  onPointerUp: PointerEventHandler<HTMLDivElement>;
}

const RoomComponent = forwardRef<HTMLDivElement, IRoomComponent>(
  ({ room, navigate, setDeleteModalIsVisible, deleteModalIsVisible, handleDeleteRoom, onPointerDown, onPointerUp }, ref) => {
    return (
      <>
        <div className={styles['room-wrapper']}>
          <div className={styles['slide']} ref={ref} onPointerDown={onPointerDown} onPointerUp={onPointerUp}>
            <div
              className={styles['room-element']}
              onClick={() => {
                room.slug && navigate(generatePath(Path.Room, { slug: room.slug }));
              }}
            >
              <span>{truncateString(room?.name, 25)}</span>
              <div
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                }}
              >
                {room.is_active ? (
                  <span className={styles['active-room-label']}>Активна</span>
                ) : (
                  <span className={styles['inactive-room-label']}>Архив</span>
                )}
              </div>
            </div>
            <Button
              className={styles['delete-button']}
              onClick={() => setDeleteModalIsVisible(room.slug)}
              textColor="#000"
              backgroundColor="white"
            >
              <TrashBin />
            </Button>
          </div>
        </div>
        <Modal visible={deleteModalIsVisible === room.slug ? true : false} onClose={() => setDeleteModalIsVisible(null)}>
          <div className={styles.modal}>
            <h2>Удалить комнату "{truncateString(room?.name, 10)}"?</h2>
            <div className={styles['button-group']}>
              <Button onClick={handleDeleteRoom}>Да</Button>
              <Button onClick={() => setDeleteModalIsVisible(null)} backgroundColor="var(--secondary-color)">
                Отмена
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  },
);

export const RoomsList = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [rooms, setRooms] = useState<Definitions.Room[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleteModalIsVisible, setDeleteModalIsVisible] = useState<string | undefined | null>(null);
  const roomRefs = useRef(new Map<string | undefined, HTMLDivElement | null>());

  let downX: number;

  const onPointerMove = (slug: string | undefined) => (event: { stopPropagation: () => void; clientX: number }) => {
    event.stopPropagation();
    const roomRef = roomRefs.current.get(slug);
    const newX = event.clientX;

    if (newX - downX < -10 && roomRef) {
      roomRef.style.transform = 'translate(-65px)';
      setTimeout(() => {
        if (roomRef) {
          roomRef.style.transform = 'translate(0px)';
        }
      }, 4000);
    } else {
      if (roomRef) roomRef.style.transform = 'translate(0px)';
    }
  };

  const onPointerDown = (slug: string | undefined) => (event: { clientX: number }) => {
    downX = event.clientX;
    roomRefs.current.get(slug)?.addEventListener('pointermove', onPointerMove(slug));
  };

  const onPointerUp = (slug: string | undefined) => () => {
    roomRefs.current.get(slug)?.removeEventListener('pointermove', onPointerMove(slug));
  };

  useEffect(() => {
    postAuthRefresh({
      refresh: localStorage.getItem(`refresh`) as string,
    }).then(() => {
      /* global process */
      fetch(process.env.REACT_APP_BACKEND_URL + '/rooms/', {
        headers: {
          Authorization: api.defaults.headers.common.Authorization as string,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setRooms(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          },
        );
    });
  }, []);

  const handleDeleteRoom = useCallback(
    (room: Definitions.Room) => async () => {
      await deleteRoom(room?.slug || '');
      setRooms((rooms) => rooms.filter((r) => r.slug !== room?.slug));
      setDeleteModalIsVisible(null);
      navigate(Path.RoomsList);
    },
    [navigate],
  );

  const handleCreate = useCallback(async () => {
    navigate(generatePath(Path.RoomsNew));
  }, [navigate]);

  return (
    <>
      <Header dark onBack={() => navigate(-1)} title="Комнаты" background="transparent" />
      <div className={styles.wrapper}>
        <div className={styles['wrapper-list']}>
          <div className={styles['room-list-wrapper']}>
            {rooms.length > 0 ? (
              rooms.map((room) => (
                <RoomComponent
                  key={room.slug}
                  room={room}
                  navigate={navigate}
                  setDeleteModalIsVisible={setDeleteModalIsVisible}
                  deleteModalIsVisible={deleteModalIsVisible}
                  handleDeleteRoom={handleDeleteRoom(room)}
                  onPointerDown={onPointerDown(room.slug)}
                  onPointerUp={onPointerUp(room.slug)}
                  ref={(el) => roomRefs.current.set(room.slug, el)}
                />
              ))
            ) : (
              <div className={classNames(styles['attempt-label'], styles['center-div'])}>
                {!isLoaded ? (
                  <p className={styles['info-component']}>Загрузка...</p>
                ) : (
                  <div>
                    <SadIcon />
                    <p className={styles.bold}>{error ? error : 'Тут еще нет ни одной комнаты'}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Button className={styles['create-room']} onClick={handleCreate}>
          Создать комнату
        </Button>
      </div>
    </>
  );
};
