import React, { useEffect } from 'react';
import { useUserAuth } from 'features/user-auth-context/lib';
import { Outlet, useNavigate } from 'react-router-dom';
import { Path } from 'shared/config';

export const AuthorizedLayout = () => {
  const { isAuthorized, isLoading } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthorized) {
      navigate(Path.Login, { replace: true });
    }
  }, [isAuthorized, isLoading, navigate]);

  if (isLoading || !isAuthorized) {
    return null;
  }

  return <Outlet />;
};
