import 'react-app-polyfill/stable';
import 'core-js/actual';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { SWRConfig } from 'swr';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';

import { App } from 'app/ui';
import { apiSWRFetcher } from 'shared/api';
import { UserAuthProvider } from 'features/user-auth-context/ui';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

const root = ReactDOM.createRoot(document.getElementById(`root`) as HTMLElement);
moment.tz.setDefault('Europe/Moscow');

function getSessionId() {
  const sessionId = Cookies.get('django_session_id');

  if (sessionId) {
    return sessionId;
  }

  const dateString = Date.now().toString(36);
  const randomness = uuidv4();

  return dateString + randomness;
}

const tomorrow = new Date();
tomorrow.setDate(new Date().getDate() + 31);

Cookies.set('django_session_id', getSessionId(), { expires: tomorrow });

root.render(
  <React.StrictMode>
    <SWRConfig value={{ fetcher: apiSWRFetcher }}>
      <UserAuthProvider>
        <Theme preset={presetGpnDefault}>
          <App />
        </Theme>
      </UserAuthProvider>
    </SWRConfig>
  </React.StrictMode>,
);
