import { Header } from 'shared/ui/header/ui';
import { Path } from 'shared/config';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input } from 'shared/ui';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ReactComponent as Check } from '../../assets/check.svg';
import { ReactComponent as Die } from '../../assets/die.svg';
import { ReactComponent as Hourglass } from '../../assets/hourglass.svg';
import { ReactComponent as Pencil } from '../../assets/pencil.svg';
import { ReactComponent as Locked } from '../../assets/locked.svg';

import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import styles from './rooms-new.module.scss';
import classNames from 'classnames';
import { postRoom, useUser } from 'entities/rooms/api';
import { toast, ToastContainer } from 'react-toastify';

const PUNKTS = [
  {
    icon: Pencil,
    text: `Придумайте название для комнаты и выберите цвет темы`,
  },
  {
    icon: Die,
    text: `Слово, которое будет загадано, выбирается случайным образом`,
  },
  {
    icon: Hourglass,
    text: `Комната будет активна 24 часа с момента ее создания`,
  },
];

type Complexity = {
  difficulty: string;
  text: string;
};

const COMPLEXITY_LEVELS = [
  { difficulty: 'easy', text: 'Легкий' },
  { difficulty: 'medium', text: 'Средний' },
  { difficulty: 'hard', text: 'Сложный' },
];

type ColorType = [string, boolean];

const COLORS: ColorType[] = [
  [`#6688CC`, false],
  [`#58BAC3`, true],
  [`#8269CF`, true],
  [`#EC6997`, true],
];

interface ColorProps {
  color: string;
  active: boolean;
  setColor: Dispatch<SetStateAction<string>>;
  locked?: boolean;
}

const Color = ({ color, setColor, active, locked = false }: ColorProps) => {
  return (
    <li style={{ background: color }} className={styles.color} onClick={() => setColor(color)}>
      {locked ? <Locked /> : active && <Check />}
    </li>
  );
};

export const RoomsNew = () => {
  const { data: user } = useUser();
  const search = useLocation().search;

  const navigate = useNavigate();
  const [error, setError] = useState(``);

  const [roomName, setRoomName] = useState(new URLSearchParams(search).get('name') || '');
  const [roomSlug, setRoomLink] = useState(new URLSearchParams(search).get('slug') || '');
  const [roomWord, setRoomWord] = useState(``);

  const [nameInvalid, setNameInvalid] = useState(false);
  const [slugInvalid, setSlugInvalid] = useState(false);
  const [wordInvalid, setWordInvalid] = useState(false);

  const [complexityValue, setComplexityValue] = useState<Complexity>(COMPLEXITY_LEVELS[1]);

  const [color, setColor] = useState(`#6688CC`);
  const [loading, setLoading] = useState(false);

  const handleCreate = useCallback(async () => {
    if (loading && !error) {
      return;
    }

    setLoading(true);

    setNameInvalid(!roomName);
    setSlugInvalid(!roomSlug);
    setWordInvalid(!roomWord);

    if (!roomName || (user?.can_create_room && (!roomSlug || !roomWord))) {
      setError('Поля не могут быть пустыми!');
      setLoading(false);
      return;
    }

    setError('');

    try {
      setError('Создаем комнату... \n Это может занять до нескольких секунд, наш искусственный интеллект подсчитывает рейтинги!');
      const { slug } = await postRoom({
        name: roomName,
        theme: color,
        slug: roomSlug,
        word: roomWord,
        difficulty: complexityValue.difficulty,
      });
      navigate(generatePath(Path.Room, { slug: slug as string }));
    } catch (error: any) {
      if (error.response.data.hasOwnProperty('slug')) {
        setSlugInvalid(true);
        setError('Уже есть комната с заданным адресом. Пожалуйста, введите другое.');
      } else if (error.response.data['error_code'] === 'word_not_found') {
        setWordInvalid(true);
        setError('Мы не знаем такого слова:(');
      } else {
        setError('Что-то пошло не так. Свяжитесь с разработчиками, спасибо!');
      }
    } finally {
      setLoading(false);
    }
  }, [color, error, loading, navigate, roomName, roomSlug, roomWord, user?.can_create_room, complexityValue.difficulty]);

  return (
    <>
      <Header onBack={() => navigate(-1)} background={color} />
      <div className={styles.wrapper}>
        <div className={styles.sheet}>
          <h2 className={styles.title}>Новая комната</h2>
          <Input placeholder="Название" value={roomName} onChange={setRoomName} className={styles.input} isInvalid={nameInvalid} />
          {user?.can_create_room && (
            <div>
              <Input
                placeholder="Ссылка на комнату"
                value={roomSlug}
                onChange={setRoomLink}
                className={styles.input}
                isInvalid={slugInvalid}
              />
              <Input placeholder="Ваше слово" value={roomWord} onChange={setRoomWord} className={styles.input} isInvalid={wordInvalid} />
            </div>
          )}

          <div className={styles['complexity-group']}>
            <span>Уровень сложности</span>
            <ChoiceGroup
              className={cnMixSpace({ mT: 'xs' })}
              name="ChoiceGroupExample"
              value={complexityValue}
              onChange={({ value }) => setComplexityValue(value)}
              items={COMPLEXITY_LEVELS}
              getItemLabel={(com) => com.text}
              form="round"
              width="full"
              size="s"
            />
          </div>

          <ul className={styles.colors}>
            {COLORS.map(([color, locked]) => (
              <Color
                key={color}
                color={color}
                locked={locked}
                setColor={locked ? () => toast('Цвет пока не доступен для выбора') : setColor}
                active={color === color}
              />
            ))}
          </ul>
          <ToastContainer autoClose={500} hideProgressBar={true} />
          <Button onClick={handleCreate} disabled={loading}>
            Создать
          </Button>
          <p className={classNames(styles.loader)}>{error}</p>
        </div>
        <ul className={styles.rules}>
          {PUNKTS.map((punkt, index) => (
            <li key={index} className={styles.rule}>
              <punkt.icon />
              <p className={styles['rule-text']}>{punkt.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
