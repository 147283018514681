import React, { useState, useEffect } from 'react';
import styles from './countdownTimer.module.scss';
import moment from 'moment-timezone';

export const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const currentDateTime = moment();
    // 18:00 МСК
    const targetTime = moment({ hour: 18, minute: 0, second: 0 });

    if (targetTime.isBefore(currentDateTime)) {
      targetTime.add(1, 'day');
    }

    const difference = targetTime.diff(currentDateTime);
    const duration = moment.duration(difference);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className={styles['countdown-wrapper']}>
      <p>Следующее слово через</p>
      <p>{timeLeft}</p>
    </div>
  );
};
