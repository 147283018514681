export enum Path {
  Guess = `/`,
  GuessWithSlug = `/:slug`,
  Menu = `/menu`,
  Profile = `/profile`,
  Login = `/login`,
  Register = `/register`,
  RoomsNew = `/rooms/new`,
  Room = `/rooms/:slug`,
  RoomsList = `/rooms`,
  MainRoom = `/main`,
  EasyRoom = `/easy`,
  HardRoom = `/hard`,
  NotFound = `/404`,
}
