import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { Path } from 'shared/config';

import { ReactComponent as Cross } from '../../assets/cross.svg';

import styles from './modal.module.scss';

interface ModalProps {
  children?: ReactNode;
  visible?: boolean;
  onClose: () => void;
  countdowntTimer?: ReactNode;
}

const modalRoot: HTMLElement | null = document.getElementById('modal-root');

export const Modal = ({ children, visible, onClose, countdowntTimer }: ModalProps) => {
  const location = useLocation();
  const { pathname } = location;

  const isOnValidPath = [Path.Guess as string, Path.HardRoom as string, Path.EasyRoom as string].includes(pathname);

  return visible && modalRoot ? (
    <>
      {createPortal(
        <div className={styles.wrapper}>
          <span className={styles.shadow} onClick={onClose}></span>
          <div className={styles.content}>
            {children}
            <Cross className={styles.close} onClick={onClose} />
          </div>
          {isOnValidPath && countdowntTimer}
        </div>,
        modalRoot,
      )}
    </>
  ) : null;
};
