import { api, routes } from 'shared/api';
import type { Paths } from 'backend';

export const postAuthRefresh = async (body: Paths.AuthJwtRefreshCreate.Parameters.Data) => {
  const { data } = await api.post<{ access: string; refresh: string }>(routes.authRefresh, body);

  localStorage.setItem(`refresh`, data.refresh);
  api.defaults.headers.common.Authorization = `JWT ${data.access}`;

  return data;
};

export const postAuthUsers = async (data: Paths.AuthUsersCreate.Parameters.Data) => {
  return (await api.post<Paths.AuthUsersCreate.Responses.$201>(routes.authUsers, data)).data;
};

export const postAuthLogin = async (body: Paths.AuthTokenLoginCreate.Parameters.Data) => {
  const { data } = await api.post<{ access: string; refresh: string }>(routes.authLogin, body);

  localStorage.setItem(`refresh`, data.refresh);
  api.defaults.headers.common.Authorization = `JWT ${data.access}`;

  return data;
};
