import { Header } from 'shared/ui/header/ui';
import { useNavigate } from 'react-router-dom';
import { Path } from 'shared/config';
import { LoginForm } from 'widgets/login-form/ui';
import React, { useCallback, useState } from 'react';
import { postAuthLogin } from 'entities/auth/api';
import { useUserAuthContext } from 'features/user-auth-context/lib';

export const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const setIsAuthorized = useUserAuthContext((contextValue) => contextValue.setIsAuthorized);

  const handleSubmit = useCallback(
    async (username: string, password: string) => {
      setIsLoading(true);
      setError(undefined);
      try {
        await postAuthLogin({ username, password });
        if (setIsAuthorized) {
          setIsAuthorized(true);
        }
      } catch (e) {
        setError(`Неверный логин или пароль`);
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsAuthorized],
  );

  return (
    <>
      <Header onBack={() => navigate(Path.Guess, { replace: true })} />
      <LoginForm
        title="Войти"
        submitText="Войти"
        suffixText="Нет аккаунта?"
        suffixLinkText="Зарегистрироваться"
        suffixLink={Path.Register}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};
