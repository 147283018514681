import axios, { AxiosRequestConfig } from 'axios';
import { routes } from 'shared/api/routes';
import { postAuthRefresh } from 'entities/auth/api';

/* global process */
const baseURL = process.env.REACT_APP_BACKEND_URL || window.location.hostname;

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;

export const api = axios.create({ baseURL });

export const apiSWRFetcher = (url: string, config?: AxiosRequestConfig) => {
  return api.get(url, config).then((response) => response.data);
};

const createResponseInterceptor = () => {
  const interceptor = api.interceptors.response.use(undefined, async (error) => {
    if (
      error.response.status !== 401 ||
      error.response.config.url.includes(routes.authLogin) ||
      error.response.config.url.includes(routes.authRefresh)
    ) {
      await Promise.reject(error);
    }

    api.interceptors.response.eject(interceptor);

    try {
      const { access, refresh } = await postAuthRefresh({
        refresh: localStorage.getItem(`refresh`) as string,
      });
      error.response.config.headers[`Authorization`] = `JWT ${access}`;
      localStorage.setItem(`refresh`, refresh);

      return await api(error.response.config);
    } catch (error) {
      localStorage.clear();
      await Promise.reject(error);
    } finally {
      createResponseInterceptor();
    }
  });
};

createResponseInterceptor();
